<template>
  <div class="main-ct">
    <div class="controls">
      <span class="button" :class="{ active: grabMode }">
        <img
          src="@/images/privacy.png"
          style="width: 28px; height: 28px"
          @click="toggleGrabMode()"
        />
      </span>
      <span class="button" :class="{ active: mapVisible }">
        <img
          src="@/images/map.png"
          style="width: 28px; height: 28px"
          @click="$emit('showMap')"
        />
      </span>
      <div class="vertical-line"></div>
      <span
        @mousedown="startZoom('zoomIn')"
        @mouseup="stopZoom"
        @mouseleave="stopZoom"
      >
        <img src="@/images/search-plus.png" style="width: 28px; height: 28px" />
      </span>
      <span style="width: 40px; text-align: center">{{ zoom }}%</span>
      <span
        @mousedown="startZoom('zoomOut')"
        @mouseup="stopZoom"
        @mouseleave="stopZoom"
      >
        <img
          src="@/images/search-minus.png"
          style="width: 28px; height: 28px"
        />
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WorkflowNavNewUi',

  props: {
    status: {
      type: Object,
      required: true
    },
    workflow: {
      type: Object,
      required: true
    },
    history: {
      type: Object,
      required: true
    },
    zoom: {
      type: Number,
      required: true
    },
    mapVisible: {
      type: Boolean,
      required: true
    }
  },

  inject: ['workflowApi', 'eventBus'],
  emits: ['toggleGrabMode'],

  data() {
    return {
      toolboxOpen: false,
      actionId: null,
      grabMode: false
    }
  },

  created() {
    this.eventBus.$on('largeToolboxOpen', (value) => (this.toolboxOpen = value))
    this.eventBus.$on(
      'multidecision-successor-change-disable-all',
      (actionId) => (this.actionId = actionId)
    )
  },

  methods: {
    startZoom(zoomDirection) {
      this.$emit(zoomDirection)
      this.zoomInterval = setInterval(() => this.$emit(zoomDirection), 200)
    },

    stopZoom() {
      clearInterval(this.zoomInterval)
    },

    toggleGrabMode() {
      this.grabMode = !this.grabMode
      this.$emit('toggleGrabMode', this.grabMode)
    }
  }
}
</script>

<style scoped>
.main-ct {
  position: absolute;
  width: 300px;
  top: 64px;
  right: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
  gap: 10px;
  margin-top: 10px;
  cursor: pointer;
}

.vertical-line {
  border-left: 6px solid #dbeee7;
  height: 100%;
}

.controls {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-radius: 6px;
  padding: 5px;
  width: 250px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  box-shadow: 4px 4px 12px 0 #0000000d;
}

.controls .button {
  width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.controls .button.active {
  border: 2px solid #53aee6;
  border-radius: 8px;
}
</style>
