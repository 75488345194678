<template>
  <div>
    <workflow-edit-nav
      v-bind="{ workflow, status, history, members, isPreview }"
    />
    <workflow-nav-new-ui
      :status="status"
      :workflow="workflow"
      :history="history"
      :zoom="zoom"
      :mapVisible="mapVisible"
      @zoomIn="zoomIn"
      @zoomOut="zoomOut"
      @showMap="onMapToggle"
      @toggleGrabMode="onToggleGrabMode"
    />

    <div
      class="toolbox-overlay"
      @click="showLargeToolbox = false"
      v-if="selectedAction && showLargeToolbox"
    />

    <transition name="slide">
      <toolbox-small
        :workflow="workflow"
        :action="selectedAction"
        :read-only="readOnly"
        v-if="!!selectedAction"
        @close="selectedActions.deselect()"
      />
    </transition>

    <transition name="slide">
      <toolbox-large
        :workflow="workflow"
        :action="selectedAction"
        :read-only="readOnly"
        v-if="selectedAction && showLargeToolbox"
        @close="showLargeToolbox = false"
      />
    </transition>

    <transition name="slide">
      <action-reporting
        :workflow="workflow"
        :action="selectedAction"
        :read-only="readOnly"
        v-if="selectedAction && showActionReporting"
        @close="showActionReporting = false"
      />
    </transition>

    <transition name="slide">
      <workflow-reporting
        :workflow="workflow"
        v-if="showWorkflowReporting"
        @close="showWorkflowReporting = false"
      />
    </transition>

    <div
      ref="viewport"
      class="workflow-viewport"
      :class="{
        'workflow-has-selection': selectedAction,
        'workflow-readonly': readOnly,
        'workflow-grabonly': grabMode
      }"
      @wheel="onWheel"
    >
      <div
        ref="canvas"
        class="workflow-canvas"
        :class="{ 'multi-select-cursor-allowed': canvasShiftHover }"
        @click="onClick"
        @mouseover="onMouseOver($event, 'canvas')"
        @mousemove="onMouseOver($event, 'canvas')"
        @dragover.prevent
        @drop="onDrop"
      >
        <add-action />
        <!--        <img src="@/images/journey-builder-background.svg" class="workflow-grid" />-->
        <component
          v-for="action in workflow.actions"
          :key="action.id"
          :is="actionType(action)"
          v-bind="{
            action,
            workflow,
            readOnly,
            selectedActions,
            grabMode,
            erroredActions
          }"
        />
      </div>
    </div>
    <workflow-side-nav v-if="!readOnly" />
    <mini-map
      v-if="mapVisible"
      ref="miniMap"
      @changeFocus="focusPosition"
    />
  </div>
</template>
<script>
import { WIDTH, HEIGHT, OFFSET_LEFT, OFFSET_TOP } from './WorkflowEdit/GridSize'

import Vue from 'vue'

import PanZoom from '@/libs/PanZoom'

import { jsPlumb } from 'jsplumb'

import WorkflowApi from '@/libs/WorkflowApi'
import ContentApi from '@/libs/ContentApi'

import WorkflowEditNav from './WorkflowEdit/WorkflowNav'
import WorkflowNavNewUi from './WorkflowEdit/WorkflowNavNewUi.vue'

import ActionCollection from './WorkflowEdit/ActionCollection'

import AddAction from './WorkflowEdit/AddAction'
import MiniMap from './WorkflowEdit/MiniMap'
import ToolboxSmall from './WorkflowEdit/ToolboxSmall'
import ToolboxLarge from './WorkflowEdit/ToolboxLarge'
import ActionReporting from './WorkflowEdit/ActionReporting'
import WorkflowReporting from './WorkflowEdit/WorkflowReporting'
import WorkflowSideNav from './WorkflowEdit/WorkflowSideNav'

import Counter from '@/libs/Counter'

export default {
  props: ['projectId', 'workflowId', 'isPreview'],

  components: Object.assign({
    WorkflowEditNav,
    MiniMap,
    ToolboxSmall,
    ToolboxLarge,
    ActionReporting,
    WorkflowReporting,
    AddAction,
    WorkflowNavNewUi,
    WorkflowSideNav
  }),

  provide() {
    const provides = {
      eventBus: new Vue(),
      jsPlumb: jsPlumb.getInstance(),
      workflowApi: this.workflowApi,
      contentApi: this.contentApi,
      zIndexCounter: new Counter(100)
    }
    Object.assign(this, provides)

    return provides
  },

  data() {
    this.workflowApi = new WorkflowApi({
      projectId: this.projectId,
      workflowId: this.workflowId
    })

    this.contentApi = new ContentApi(this.projectId)

    return {
      selectedActions: new ActionCollection(),
      showLargeToolbox: false,
      showActionReporting: false,
      showWorkflowReporting: false,
      shiftHover: false,
      canvasShiftHover: false,
      mapVisible: false,
      grabMode: false,
      zoom: 100,
      erroredActions: []
    }
  },

  watch: {
    'workflow.name': {
      immediate: true,
      handler() {
        document.title = `${this.workflow.name} - Maxautomation`
      }
    },

    selectedAction: 'updateSelected',
    showLargeToolbox: 'updateSelected',
    showActionReporting: 'updateSelected',

    'workflow.actions': {
      immediate: true,
      deep: true,
      handler() {
        this.selectedActions.setActions(this.workflow.actions)
      }
    },

    'status.messages'(messages) {
      this.erroredActions = []
      messages.forEach((message) => {
        if (message.type === 'error') {
          this.erroredActions[message.id] = true
        }
      })
    }
  },

  computed: {
    workflow() {
      return this.workflowApi.workflow
    },

    status() {
      return this.workflowApi.status
    },

    history() {
      return this.workflowApi.history
    },

    members() {
      return this.workflowApi.members
    },

    selectedAction() {
      if (!this.shiftHover) {
        if (this.selectedActions.count !== 1) {
          return null
        }

        return this.selectedActions.getOneAction()
      }
    },

    readOnly() {
      return (
        this.workflow.isRunning ||
        this.isPreview ||
        !this.$can('ROLE_WORKFLOW_EDITOR') ||
        this.workflow.isSnapshot
      )
    }
  },

  methods: {
    onMapToggle() {
      this.mapVisible = !this.mapVisible
    },
    onDrop(event) {
      const type = event.dataTransfer.getData('text/plain');
      const pos = [event.offsetX - OFFSET_LEFT - 150, event.offsetY - OFFSET_TOP - 30]
      this.workflowApi.createAction(
        [],
        {
          type: type,
          pos: pos.join(',')
        }
      )
    },
    onMouseOver(e, area = null) {
      if (area) {
        this.canvasShiftHover = e.shiftKey
        return
      }
      this.shiftHover = e.shiftKey
    },

    actionType(action) {
      return require(`./WorkflowEdit/Actions/${action.type}`).default
    },

    onClick(e) {
      if (e.target === this.$refs.canvas) {
        this.selectAction(false)
      }
    },

    onWheel(e) {
      if (this.isPreview) {
        return
      }
      if (this.selectedAction) {
        return
      }

      e.preventDefault()
      e.stopPropagation()

      const pan = this.panZoom.transform

      this.panZoom.setTransform(
        {
          left: pan.left - e.deltaX,
          top: pan.top - e.deltaY
        },
        {
          animate: false
        }
      )
    },

    selectAction(actionId, options = {}) {
      this.selectedActions.deselect()
      if (actionId !== false) {
        this.selectedActions.selectAction(actionId)
      }

      this.showLargeToolbox = false
      this.showActionReporting = this.workflow.isSnapshot
        ? false
        : !!options.showActionReporting
      this.showWorkflowReporting = false
      this.eventBus.$emit('hideAddAction')
    },

    clearSelection() {
      this.storePanZoom = null
      this.selectAction(false)
    },

    updateSelected() {
      this.eventBus.$emit('largeToolboxOpen', this.showLargeToolbox)
      if (this.selectedAction) {
        if (!this.storePanZoom) {
          this.storePanZoom = JSON.parse(JSON.stringify(this.panZoom.transform))
        }

        const actionEl = this.$refs.canvas.querySelector(
          `[data-action-id='${this.selectedAction.id}']`
        )

        if (this.showLargeToolbox || this.showActionReporting) {
          this.panZoom.focusElement(actionEl, {
            animate: true,
            offsetLeft: -600,
            offsetTop: -50,
            constrain: 'top'
          })
        } else {
          this.panZoom.focusElement(actionEl, {
            animate: true,
            offsetLeft: -250,
            offsetTop: -50,
            constrain: 'top'
          })
        }
      } else if (this.storePanZoom) {
        this.panZoom.setTransform(this.storePanZoom)
        this.storePanZoom = null
      }
    },

    focusPosition(pos) {
      this.panZoom.focusPosition(pos.x, pos.y)
    },

    zoomIn() {
      this.panZoom.zoomIn()
      this.jsPlumb.setZoom(this.panZoom.transform.scale)
      this.zoom = Math.round(this.panZoom.transform.scale * 100) + 20
    },

    zoomOut() {
      this.panZoom.zoomOut()
      this.jsPlumb.setZoom(this.panZoom.transform.scale)
      this.zoom = Math.round(this.panZoom.transform.scale * 100) + 20
    },

    onToggleGrabMode(grabMode) {
      this.grabMode = grabMode
      this.panZoom.options.grabMode = grabMode
    }
  },

  created() {
    this.$store.dispatch('fetchWorkflowPromotions')
    this.$store.dispatch('fetchPromotions')
    this.$store.dispatch('fetchConsentPreferences')
    this.$i18n.locale = this.$store.getters.getLanguage
    this.eventBus.$on('selectAction', (actionId, options) =>
      this.selectAction(actionId, options)
    )
    this.eventBus.$on('clearSelection', () => this.clearSelection())
    this.eventBus.$on('showLargeToolbox', (show) => {
      this.showLargeToolbox = show
      this.showActionReporting = false
    })
    this.eventBus.$on('showActionReporting', (show) => {
      if (this.workflow.isSnapshot) {
        return
      }
      this.showLargeToolbox = false
      this.showActionReporting = show
    })
    this.eventBus.$on('showWorkflowReporting', (show) => {
      if (this.workflow.isSnapshot) {
        return
      }
      this.selectAction(false)
      this.showWorkflowReporting = show
    })
    this.eventBus.$on('onShift', (show) => {
      this.shiftHover = show
    })

    this.jsPlumb.setSuspendDrawing(true)

    this.jsPlumb.bind('connectionDrag', () => this.clearSelection())
    this.jsPlumb.bind('beforeStartDetach', (connection) => {
      if (connection.endpoint.isTarget) {
        return false
      }

      connection.endpoint.connections.forEach((connection) => {
        if (connection.isDetachable()) {
          this.jsPlumb.deleteConnection(connection)
        }
      })
    })

    this.jsPlumb.bind('connectionDrag', (info) =>
      this.eventBus.$emit('connectionDrag', info)
    )
    this.jsPlumb.bind('connectionDragStop', (info) =>
      this.eventBus.$emit('connectionDragStop', info)
    )
    this.jsPlumb.bind('connection', (info) =>
      this.eventBus.$emit('connection', info)
    )
    this.jsPlumb.bind('connectionDetached', (info) =>
      this.eventBus.$emit('connectionDetached', info)
    )
  },

  mounted() {
    this.$refs.canvas.style.width = `${WIDTH}px`
    this.$refs.canvas.style.height = `${HEIGHT}px`

    this.jsPlumb.setContainer(this.$refs.canvas)

    this.panZoom = new PanZoom(this.$refs.canvas, { grabMode: this.grabMode })
    this.panZoom.on('panning', () => this.clearSelection())
    this.panZoom.on('zoom', () => this.clearSelection())
    this.jsPlumb.setZoom(this.panZoom.transform.scale)

    this.selectedActions.setWorkflowApi(this.workflowApi)
    this.selectedActions.setEventBus(this.eventBus)
    this.selectedActions.setActions(this.workflow.actions)

    this.eventBus.$on('startActionMounted', () => {
      let startEl = this.$refs.canvas.querySelector(`[data-action-id='Start']`)
      this.panZoom.focusElement(startEl, { animate: false })
      this.jsPlumb.setSuspendDrawing(false, true)
      this.eventBus.$off('startActionMounted')
    })
  },

  beforeDestroy() {
    this.jsPlumb.cleanupListeners()
    this.workflowApi.$destroy()
    this.panZoom.destroy()
  }
}
</script>
<style lang="sass" scoped>
.workflow-readonly ::v-deep .jtk-endpoint, .workflow-grabonly ::v-deep .jtk-endpoint
    display: none

.workflow-grabonly
    cursor: grab

.workflow-grabonly ::v-deep .action-connector-hover
    filter: none
    path
      stroke-width: 2px

.workflow-viewport
    user-select: none
    position: fixed
    top: 64px
    left: 0
    right: 0
    bottom: 0
    overflow: hidden
    z-index: 1
    background-color: rgb(248,250,252)

.workflow-grid
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    pointer-events: none

.workflow-canvas
    position: relative

.slide-enter-active,
.slide-leave-active
    transition: transform .3s ease

.slide-enter,
.slide-leave-to
    transform: translateX(100%)

.toolbox-overlay
    position: fixed
    top: 0
    bottom: 0
    left: 0
    right: 0
    background-color: rgba(0, 0, 0, 0.3)
    display: flex
    justify-content: center
    align-items: center
    z-index: 4

.multi-select-cursor-allowed
    cursor: copy
</style>
