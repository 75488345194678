<template>
  <div
    class="action-control"
    v-if="!workflow.isSnapshot && !readOnly && !grabMode && showControls"
  >
    <icon
      glyph="bin"
      size="25"
      :custom-svg-styles="{color: '#4B515F', fill: '#e6f3fb'}"
      @click="deleteAction($event)"
    />
    <icon
      glyph="copy-action"
      size="25"
      :custom-svg-styles="{color: '#4B515F', fill: '#e6f3fb'}"
      @click="copyAction($event)"
    />
  </div>
</template>

<script>
import { CELL_SIZE } from '@/views/WorkflowEdit/GridSize'
import alert from '@/libs/Alert'
export default {
  name: 'ActionControls',
  props: {
    workflow: {
      type: Object,
      required: true
    },
    readOnly: {
      type: Boolean,
      required: true
    },
    showControls: {
      type: Boolean,
      required: true
    },
    action: {
      type: Object,
      required: true
    },
    grabMode: {
      type: Boolean,
      required: true
    }
  },
  emits: ['delete-action'],
  inject: ['workflowApi'],
  methods: {
    deleteAction(event) {
      event.stopPropagation()
      alert.show({
        confirm: true,
        type: 'warning',
        okText: this.$t('deleteDialogOkText'),
        title: this.$t('deleteDialogTitle'),
        htmlMessage: true,
        message: this.$t('deleteDialogMessage', {
          actionName: this.action.name
        }),
        onOk: (resolve) => {
          this.workflowApi.deleteAction(this.action.id)
          resolve()
        }
      })
    },

    copyAction(event) {
      event.stopPropagation()
      let position = this.action.pos.split(',')
      position[0] = parseInt(position[0]) + CELL_SIZE
      position[1] = parseInt(position[1]) + CELL_SIZE
      this.workflowApi.copyAction(this.action.id, position.join(','))
    }
  }
}
</script>

<style scoped>
.action-control {
  display: flex;
  flex-direction: column;
  height: auto;
  background-color: #e6f3fb;
  justify-content: space-around;
  padding: 0 5px 5px 5px;
  transition: width 2s;
  cursor: pointer;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  gap: 5px;
}
</style>
