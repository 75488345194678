<template>
  <div class="stats-ct">
    <span class="stat">
      <icon class="stat-icon" glyph="action-stat-arrived" />
      <span>{{ $t('actionTooltipArrived') }}</span>
      <span class="stat-number">{{ numberFilter(stats['End:completed']) }}</span>
    </span>
  </div>
</template>
<script>
import { numberFilter } from '@/helpers/filters'

export default {
  props: ['stats'],
  data() {
    return {
      numberFilter
    }
  }
}
</script>
