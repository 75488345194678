<template>
  <div class="stats-ct">
    <span class="stat">
      <icon class="stat-icon" glyph="action-stat-delayed" />
      <span>{{ $t('delayStatTotalDelayed') }}</span>
      <span class="stat-number">{{ numberFilter(stats['Delay:delayed']) }}</span>
    </span>
    <span class="stat">
      <icon class="stat-icon" glyph="action-stat-waiting" />
      <span>{{ $t('delayStatCurrentlyWaiting') }}</span>
      <span class="stat-number">{{ numberFilter(waiting) }}</span>
    </span>
    <span class="stat">
      <icon class="stat-icon" glyph="action-stat-endedearly" />
      <span>{{ $t('delayStatTotalEndedEarly') }}</span>
      <span class="stat-number">{{ numberFilter(stats['Delay:earlyCancel']) }}</span>
    </span>
  </div>
</template>
<script>
import { numberFilter } from '@/helpers/filters'

export default {
  props: ['stats'],

  data() {
    return {
      numberFilter
    }
  },

  computed: {
    waiting() {
      return (
        (Number(this.stats['Delay:delayed']) || 0) -
        (Number(this.stats['Delay:resumed']) || 0) -
        (Number(this.stats['Delay:cancelled']) || 0) -
        (Number(this.stats['Delay:earlyCancel']) || 0)
      )
    }
  }
}
</script>
<style scoped>
.stats-ct {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.stat {
  display: flex;
  gap: 5px;
  align-items: baseline;
}

.stat-number {
  font-weight: 400;
}
</style>
