import { render, staticRenderFns } from "./ProfileActivity.vue?vue&type=template&id=5bd544cb&scoped=true"
import script from "./ProfileActivity.vue?vue&type=script&lang=js"
export * from "./ProfileActivity.vue?vue&type=script&lang=js"
import style0 from "./ProfileActivity.vue?vue&type=style&index=0&id=5bd544cb&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bd544cb",
  null
  
)

export default component.exports