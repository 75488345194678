<template>
  <div class="stats-ct">
    <span class="stat">
      <icon class="stat-icon" glyph="action-stat-arrived" />
      <span>{{ $t('actionTooltipArrived') }}</span>
      <span class="stat-number">{{ numberFilter(entered) }}</span>
    </span>
    <span class="stat">
      <icon class="stat-icon" glyph="tick-box" />
      <span>{{ $t('yesLabel') }}</span>
      <span class="stat-number">{{ numberFilter(stats['Decision:evaluatedYes']) }}</span>
    </span>
    <span class="stat">
      <icon class="stat-icon" glyph="cross-circle" />
      <span>{{ $t('noLabel') }}</span>
      <span class="stat-number">{{ numberFilter(stats['Decision:evaluatedNo']) }}</span>
    </span>
  </div>
</template>
<script>
import { numberFilter } from '@/helpers/filters'

export default {
  props: ['stats'],

  data() {
    return {
      numberFilter
    }
  },

  computed: {
    entered() {
      return (
        (Number(this.stats['Decision:evaluatedYes']) || 0) +
        (Number(this.stats['Decision:evaluatedNo']) || 0)
      )
    }
  }
}
</script>
<style scoped>
.stats-ct {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.stat {
  display: flex;
  gap: 5px;
  align-items: baseline;
}

.stat-number {
  font-weight: 400;
}
</style>
