<template>
  <div class="add-action" :class="{ 'sub-menu': showSubMenu }">
    <icon
      glyph="messages"
      style="cursor: default"
      v-tooltip:top="$t('addActionMessageTooltipMessagesTitle')"
      :class="{ 'action-style-email': hoverAction === 'Messages' }"
      @mouseover.native="hoverAction = 'Messages'"
    />

    <action-icon
      action-type="Delay"
      v-tooltip:top="$t('addActionMessageTooltipDelayTitle')"
      :class="{ 'action-style-delay': hoverAction === 'Delay' }"
      @mouseover.native="hoverAction = 'Delay'"
      @click="add('Delay')"
    />

    <icon
      glyph="branch"
      style="cursor: default"
      v-tooltip:top="$t('addActionMessageTooltipBranchesTitle')"
      :class="{ 'action-style-decision': hoverAction === 'Branches' }"
      @mouseover.native="hoverAction = 'Branches'"
    />

    <icon
      glyph="user-single"
      style="cursor: default"
      v-tooltip:top="$t('addActionMessageTooltipUserActionsTitle')"
      :class="{ 'action-style-adretarget': hoverAction === 'AdRetarget' }"
      @mouseover.native="hoverAction = 'AdRetarget'"
    />

    <action-icon
      action-type="End"
      v-tooltip:top="$t('addActionMessageTooltipEndTitle')"
      :class="{ 'action-style-end': hoverAction === 'End' }"
      @mouseover.native="hoverAction = 'End'"
      @click="add('End')"
    />

    <div
      style="position: absolute; top: 64px; height: 64px; width: 100%"
      :class="subMenuClass"
    >
      <div v-show="hoverAction === 'Messages'">
        <action-icon
          action-type="Email"
          v-tooltip:bottom="$t('addActionMessageTooltipEmailTitle')"
          @click="add('Email')"
          :class="{ disabled: !emailEnabled }"
        />

        <action-icon
          action-type="Sms"
          v-tooltip:bottom="$t('addActionMessageTooltipSmsTitle')"
          @click="add('Sms')"
          :class="{ disabled: !smsEnabled }"
        />

        <action-icon
          action-type="Push"
          v-tooltip:bottom="$t('addActionMessageTooltipPushTitle')"
          @click="add('Push')"
          :class="{ disabled: !pushEnabled }"
        />

        <action-icon
          action-type="Inbox"
          v-tooltip:bottom="$t('addActionMessageTooltipInboxTitle')"
          @click="add('Inbox')"
          :class="{ disabled: !inboxEnabled }"
        />
      </div>

      <div v-show="hoverAction === 'Branches'">
        <action-icon
          action-type="Decision"
          v-tooltip:bottom="$t('addActionMessageTooltipDecisionTitle')"
          @click="add('Decision')"
        />

        <action-icon
          action-type="MultiDecision"
          v-tooltip:bottom="$t('addActionMessageTooltipMultiDecisionTitle')"
          @click="add('MultiDecision')"
        />

        <action-icon
          action-type="Split"
          v-tooltip:bottom="$t('addActionMessageTooltipSplitTitle')"
          @click="add('Split')"
        />
      </div>

      <div v-show="hoverAction === 'AdRetarget'">
        <action-icon
          action-type="MultipleUser"
          v-tooltip:bottom="$t('addActionMessageTooltipAdRetargetingTitle')"
          @click="add('AdRetarget')"
          :class="{ disabled: !adRetargetEnabled }"
        />

        <action-icon
          action-type="Promotion"
          v-tooltip:bottom="$t('addActionMessageTooltipPromotionTitle')"
          @click="add('Promotion')"
          :class="{ disabled: !promotionsEnabled }"
        />
      </div>
    </div>
  </div>
</template>
<script>
import appConfig from '@/config/config'

const subMenus = {
  Messages: 'action-style-email',
  API: 'action-style-email',
  Branches: 'action-style-branches',
  AdRetarget: 'action-style-adretarget'
}

export default {
  inject: ['jsPlumb', 'workflowApi', 'eventBus'],

  data() {
    return {
      hoverAction: '',
      smsDisabledTooltip: this.$t('addActionSmsDisabledToolTip')
    }
  },

  computed: {
    showSubMenu() {
      return Object.keys(subMenus).includes(this.hoverAction)
    },

    subMenuClass() {
      return subMenus[this.hoverAction]
    },

    emailEnabled() {
      return this.$store.getters.emailEnabled
    },

    smsEnabled() {
      return this.$store.getters.smsEnabled
    },

    pushEnabled() {
      return this.$store.getters.pushEnabled
    },

    inboxEnabled() {
      return this.$store.getters.inboxEnabled
    },

    adRetargetEnabled() {
      return (
        this.$store.getters.isFeatureEnabled('meta') ||
        this.$store.getters.isFeatureEnabled('googleads')
      )
    },

    promotionsEnabled() {
      return this.$store.state.project.workflowPromotions.length > 0
    }
  },

  created() {
    this.eventBus.$on('showAddAction', this.show)
    this.eventBus.$on('hideAddAction', this.hide)
  },

  mounted() {
    this.input = this.jsPlumb.addEndpoint(this.$el, {
      uuid: 'addAction:input',
      isTarget: true,
      detachable: false,
      anchor: 'TopCenter',
      maxConnections: 1,
      endpoint: 'Blank'
    })
  },

  beforeDestroy() {
    this.eventBus.$off('showAddAction', this.show)
    this.eventBus.$off('hideAddAction', this.hide)
    this.jsPlumb.removeAllEndpoints(this.$el)
  },

  methods: {
    add(type) {
      if (
        (type === 'Email' && !this.emailEnabled) ||
        (type === 'Sms' && !this.smsEnabled) ||
        (type === 'Push' && !this.pushEnabled) ||
        (type === 'Inbox' && !this.inboxEnabled) ||
        (type === 'AdRetarget' && !this.adRetargetEnabled) ||
        (type === 'Promotion' && !this.promotionsEnabled)
      ) {
        return
      }

      const actionVm = this.actionVm,
        successorName = this.successorName,
        successorOffset = actionVm.$options.successorOffset

      this.hide()

      const pos = actionVm.action.pos.split(',')
      pos[0] = parseInt(pos[0]) + successorOffset[successorName].left
      pos[1] =
        parseInt(pos[1]) +
        parseInt(actionVm.$el.offsetHeight) +
        successorOffset[successorName].top
      this.workflowApi.createAction(
        [
          {
            id: actionVm.action.id,
            successorName
          }
        ],
        {
          type: type,
          pos: pos.join(',')
        }
      )
    },

    show(actionVm, successorName) {
      this.hide()
      this.eventBus.$emit('clearSelection')

      this.$el.style.display = 'block'

      let left = actionVm.$el.offsetLeft
      left += actionVm.$el.offsetWidth / 2
      left += actionVm.$options.successorOffset[successorName].left
      left -= this.$el.offsetWidth / 2

      this.$el.style.left = `${left}px`

      let top = actionVm.$el.offsetTop
      top += actionVm.$el.offsetHeight
      top += actionVm.$options.successorOffset[successorName].top

      this.$el.style.top = `${top}px`

      this.input.setVisible(true)
      this.jsPlumb.connect({
        uuids: [`${actionVm.action.id}:${successorName}`, 'addAction:input']
      })

      this.jsPlumb.revalidate(this.$el)

      this.actionVm = actionVm
      this.successorName = successorName
    },

    hide() {
      this.hoverAction = ''
      this.actionVm = null
      this.successorName = null

      this.input.connections.forEach((connection) =>
        this.jsPlumb.deleteConnection(connection)
      )
      this.input.setVisible(false)
      this.$el.style.display = 'none'
    }
  }
}
</script>
<style lang="sass" scoped>
.add-action
    position: absolute
    display: none
    top: -1000px
    left: -1000px
    border-radius: 5px
    border: 1px solid #808080
    background-color: #808080
    box-sizing: border-box
    box-shadow: 5px 5px 10px #9b9b9b
    overflow: hidden
    z-index: 999
    transition: height .25s
    height: 64px

    &.sub-menu
        height: 128px

    .icon
        float: left
        color: #fff
        padding: 17px
        width: 64px
        height: 64px
        cursor: pointer

        &.disabled
            cursor: not-allowed
            opacity: .3
</style>
