<template>
  <div
    class="icon"
    :class="'icon-' + glyph"
    :style="style"
    @click="$emit('click', $event)"
  >
    <svg :style="svgStyle">
      <use v-bind="{ 'xlink:href': '#' + glyph }" />
    </svg>
  </div>
</template>
<script>
export default {
  props: {
    glyph: {
      required: true
    },
    size: {
      type: String
    },
    customSvgStyles: {
      type: Object,
      required: false,
      default() {
        return {}
      }
    }
  },

  computed: {
    svgStyle() {
      const style = {
        width: '100%',
        height: '100%'
      }

      return {...style, ...this.customSvgStyles}
    },
    style() {
      const style = {}
      if (this.size) {
        let size = this.size
        if (String(size).match(/^\d+$/)) {
          size += 'px'
        }
        style.width = size
        style.height = size
      }

      return style
    }
  },

  watch: {
    glyph: {
      immediate: true,
      handler() {
        // https://webpack.js.org/guides/dependency-management/
        require('../svg/' + this.glyph + '.svg')
      }
    }
  }
}
</script>
<style lang="sass">
.icon
    display: inline-block
    width: 1em
    height: 1em

.icon svg
    pointer-events: none
    position: relative
    bottom: -0.125em
</style>
