<template>
  <div>
    <div class="action-menu">
      <div class="section-container" v-for="(section, title) in actions">
        <div class="section-title">{{ title }}</div>
        <div class="section-items">
          <div v-for="action in section">
            <div
              class="section-item"
              draggable="true"
              @dragstart="onDragStart($event, action.type)"
              @dragend="onDragEnd"
              :style="{ opacity: isDragging ? '0.5' : '1' }"
            >
              <icon
                :glyph="ActionIcons[action.type].icon"
                size="38"
                :custom-svg-styles="{
                  color: ActionIcons[action.type].color,
                  fill: ActionIcons[action.type].fill
                }"
              />
              <div class="section-item-title">{{ action.title }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ActionIcons } from '@/enums/ActionIconEnum'

export default {
  name: 'WorkflowSideNav',

  data() {
    return {
      ActionIcons,
      isDragging: false
    }
  },

  computed: {
    actions() {
      const actions = {
        [this.$t('sidenavCategoryEngagement')]: [],
        [this.$t('sidenavCategoryDelay')]: [
          {
            title: this.$t('sidenavDelayBy'),
            type: 'Delay'
          }
        ],
        [this.$t('sidenavCategoryBranches')]: [
          {
            title: this.$t('sidenavDecision'),
            type: 'Decision'
          },
          {
            title: this.$t('sidenavMultidecision'),
            type: 'MultiDecision'
          },
          {
            title: this.$t('sidenavSplit'),
            type: 'Split'
          }
        ],
        [this.$t('sidenavCategoryUserActions')]: [],
        [this.$t('sidenavCategoryControls')]: [
          {
            title: this.$t('sidenavFinish'),
            type: 'End'
          }
        ]
      }

      if (this.emailEnabled) {
        actions[this.$t('sidenavCategoryEngagement')].push({
          title: this.$t('sidenavEmail'),
          type: 'Email'
        })
      }

      if (this.smsEnabled) {
        actions[this.$t('sidenavCategoryEngagement')].push({
          title: this.$t('sidenavSms'),
          type: 'Sms'
        })
      }

      if (this.pushEnabled) {
        actions[this.$t('sidenavCategoryEngagement')].push({
          title: this.$t('sidenavPush'),
          type: 'Push'
        })
      }

      if (this.inboxEnabled) {
        actions[this.$t('sidenavCategoryEngagement')].push({
          title: this.$t('sidenavInbox'),
          type: 'Inbox'
        })
      }

      if (this.promotionsEnabled) {
        actions[this.$t('sidenavCategoryUserActions')].push({
          title: this.$t('sidenavPromotions'),
          type: 'Promotion'
        })
      }

      if (this.adRetargetEnabled) {
        actions[this.$t('sidenavCategoryUserActions')].push({
          title: this.$t('sidenavAdRetarget'),
          type: 'AdRetarget'
        })
      }

      if (!this.promotionsEnabled && !this.adRetargetEnabled) {
        delete actions['user actions']
      }

      return actions
    },

    emailEnabled() {
      return this.$store.getters.emailEnabled
    },

    smsEnabled() {
      return this.$store.getters.smsEnabled
    },

    pushEnabled() {
      return this.$store.getters.pushEnabled
    },

    inboxEnabled() {
      return this.$store.getters.inboxEnabled
    },

    adRetargetEnabled() {
      return (
        this.$store.getters.isFeatureEnabled('meta') ||
        this.$store.getters.isFeatureEnabled('googleads')
      )
    },

    promotionsEnabled() {
      return this.$store.state.project.workflowPromotions.length > 0
    }
  },

  methods: {
    glyph(action) {
      return require('../../svg/' + action.icon + '.svg')
    },

    onDragStart(event, type) {
      event.dataTransfer.setData('text/plain', type);
      this.isDragging = true;
    },

    onDragEnd() {
      this.isDragging = false;
    }
  }
}

</script>
<style lang="sass" scoped>
.action-menu
  position: absolute
  z-index: 2
  left: 0
  top: 64px
  width: 200px
  background-color: #ffffff
  padding-top: 10px

  .section-container
    width: 150px
    margin-left: auto
    margin-right: auto
    margin-bottom: 10px

    &:not(:last-child)
      border-bottom: 1px solid #DBDEE7

    .section-title
      color: #4B515F
      font-size: 18px
      font-weight: 600
      margin-bottom: 10px
      text-transform: capitalize

    .section-items
      display: flex
      flex-wrap: wrap
      gap: 10px

      .section-item
        width: 70px
        margin-bottom: 10px
        display: flex
        flex-direction: column
        align-items: center

        .section-item-title
          font-size: 12px
          font-weight: 600
          color: #4B515F
          text-align: center
          margin-top: 5px

</style>
