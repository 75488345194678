<template>
  <div class="stats-ct">
    <span class="stat">
      <icon class="stat-icon" glyph="action-stat-sent" />
      <span>{{ $t('actionTooltipSent') }}</span>
      <span class="stat-number">{{ numberFilter(stats['Email:sent']) }}</span>
    </span>
    <span class="stat">
      <icon class="stat-icon" glyph="action-stat-opened" />
      <span>{{ $t('actionTooltipOpens') }}</span>
      <span class="stat-number">{{ opens }}</span>
    </span>
    <span class="stat">
      <icon class="stat-icon" glyph="action-stat-clicked" />
      <span>{{ $t('actionTooltipClicks') }}</span>
      <span class="stat-number">{{ clicks }}</span>
    </span>
  </div>
</template>
<script>
import { numberFilter } from '@/helpers/filters'

export default {
  props: ['stats', 'trackEmailOpen', 'trackEmailClick'],

  data() {
    return {
      numberFilter
    }
  },

  computed: {
    opens() {
      if (!this.trackEmailOpen) return 'N/A'

      return this.numberFilter(this.stats['Email:openedUnique'])
    },

    clicks() {
      if (!this.trackEmailClick) return 'N/A'

      return this.numberFilter(this.stats['Email:clickedUnique'])
    }
  }
}
</script>
<style scoped>
.stats-ct {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.stat {
  display: flex;
  gap: 5px;
  align-items: baseline;
}

.stat-number {
  font-weight: 400;
}
</style>
