import html from 'html-template-tag'
import alert from '@/libs/Alert'
import { CELL_SIZE } from './GridSize'
import i18n from '@/config/i18n/index'

class ActionCollection {
  constructor() {
    this.actionIds = []
  }

  get count() {
    return this.actionIds.length
  }

  setActions(actions) {
    this.actions = actions
  }

  setEventBus(eventBus) {
    this.eventBus = eventBus
  }

  setWorkflowApi(workflowApi) {
    this.workflowApi = workflowApi
  }

  getAction(actionId) {
    return this.actions.find((action) => action.id === actionId)
  }

  getOneAction() {
    return this.getAction(this.actionIds[0])
  }

  selectAction(actionId) {
    this.actionIds = [actionId]
  }

  toggleSelect(actionId) {
    let startPos = this.actionIds.indexOf('Start')
    if (startPos >= 0) {
      this.actionIds.splice(startPos, 1)
    }
    let pos = this.actionIds.indexOf(actionId)
    if (pos >= 0) {
      this.actionIds.splice(pos, 1)
      return
    }
    this.actionIds.push(actionId)
  }

  isSelected(actionId) {
    return this.actionIds.indexOf(actionId) >= 0
  }

  deselect() {
    this.actionIds = []
  }

  delete() {
    let message = i18n.t('deleteDialogTitle')
    if (this.count === 1) {
      message = html`
        ${i18n.t('deleteDialogMessage', {
          actionName: this.getAction(this.actionIds[0]).name
        })}
        "
      `
    }

    alert.show({
      confirm: true,
      type: 'warning',
      okText: i18n.t('deleteDialogOkText'),
      title: i18n.t('deleteDialogTitle'),
      htmlMessage: true,
      message: message,
      onOk: (resolve) => {
        this.actionIds.forEach((actionId) => {
          this.workflowApi.deleteAction(actionId)
        })
        this.deselect()
        resolve()
      }
    })
  }

  copy() {
    this.actionIds.forEach((actionId) => {
      let action = this.getAction(actionId)
      const pos = action.pos.split(',')
      pos[0] = parseInt(pos[0]) + CELL_SIZE
      pos[1] = parseInt(pos[1]) + CELL_SIZE
      this.workflowApi.copyAction(actionId, pos.join(','))
    })

    this.deselect()
  }

  startMove(initialPos) {
    this.initialPos = initialPos
    this.startPositions = {}
    this.actionIds.forEach((actionId) => {
      let action = this.getAction(actionId)
      this.startPositions[actionId] = action.pos
        .split(',')
        .map((x) => parseInt(x))
    })
  }

  move(exceptActionId, pos, update = false) {
    if (pos[0] === this.initialPos[0] && pos[1] === this.initialPos[1]) {
      return
    }
    let relativeMove = [
      pos[0] - this.initialPos[0],
      pos[1] - this.initialPos[1]
    ]
    this.actionIds
      .filter((actionId) => actionId !== exceptActionId)
      .forEach((actionId) => {
        let startPos = this.startPositions[actionId]
        let newPos = [
          startPos[0] + relativeMove[0],
          startPos[1] + relativeMove[1]
        ].join(',')

        this.eventBus.$emit('moveAction', {
          actionId,
          newPos,
          update,
          startPos
        })
      })
  }

  endMove() {
    delete this.startPositions
    delete this.initialPos
  }
}

export default ActionCollection
