<template>
  <div class="action-ct">
    <i v-if="errored" class="validation-badge pi pi-exclamation-circle" />
    <div class="action-header">
      <icon
        :glyph="getActionIconData.icon"
        size="39"
        :custom-svg-styles="{
          color: getActionIconData.color,
          fill: getActionIconData.fill
        }"
      />
      <div class="action-title">{{ action.name }}</div>
    </div>
    <div
      v-if="workflowHasBeenOpened"
      class="analytics-wrapper"
      @click="toggleAnalytics($event)"
    >
      <span
        class="analytics-btn"
        v-if="!showAnalyticsDropdown || !statsAvailable"
      >
        {{ $t('actionViewAnalytics') }}
      </span>
      <span>
        <icon
          v-if="statsAvailable"
          :glyph="showAnalyticsDropdown ? 'chevron-up' : 'chevron-down'"
          size="12"
        />
        <icon v-else glyph="chevron-right" size="12" />
      </span>
    </div>
    <div
      class="analytics-content"
      v-if="statsAvailable && showAnalyticsDropdown"
    >
      <slot name="stats"></slot>
    </div>
  </div>
</template>

<script>
import { ActionIcons } from '@/enums/ActionIconEnum'

export default {
  name: 'ActionNewUi',
  inject: ['workflowApi'],
  props: {
    action: {
      type: Object,
      required: true
    },
    grabMode: {
      type: Boolean,
      required: true
    },
    errored: {
      type: Boolean,
      required: true
    }
  },
  emits: ['toggle-analytics', 'show-reporting'],
  data() {
    return {
      ActionIcons,
      showAnalyticsDropdown: false
    }
  },
  mounted() {
    if (this.workflowHasBeenOpened) {
      this.showAnalyticsDropdown = true
    }
  },
  computed: {
    getActionIconData() {
      return this.ActionIcons[this.workflowApi.getActionType(this.action)]
    },

    statsAvailable() {
      let noStatActions = ['MultiDecision', 'Split']
      return !noStatActions.includes(this.action.type)
    },

    getAnalyticsIconStyling() {
      if (this.showAnalyticsDropdown) {
        return {
          transform: 'rotate(180deg)'
        }
      }

      return { transform: 'rotate(0)' }
    },

    workflowHasBeenOpened() {
      return this.workflowApi.workflow.hasBeenOpened
    }
  },
  methods: {
    toggleAnalytics(event) {
      if (this.grabMode) {
        return
      }
      event.stopPropagation()
      if (!this.statsAvailable) {
        return this.$emit('show-reporting', event)
      }
      this.showAnalyticsDropdown = !this.showAnalyticsDropdown
    }
  },
  watch: {
    showAnalyticsDropdown() {
      this.$emit('toggle-analytics', this.showAnalyticsDropdown)
    }
  }
}
</script>

<style scoped>
.action-ct {
  min-height: 60px;
  min-width: 250px;
  padding: 7px 9px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 18px;
  font-weight: 600;
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 4px 4px 12px 0 #0000000d;
}

.analytics-wrapper {
  display: flex;
  align-self: flex-end;
  justify-content: flex-end;
  align-items: baseline;
  text-decoration: underline;
  gap: 5px;
}

.analytics-content {
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 12px;
  color: #4b515f;
}

.action-header {
  display: flex;
  align-items: center;
  gap: 5px;
}

.action-title {
  color: #4b515f;
  font-size: 14px;
  font-weight: 600;
  align-self: center;
  margin-left: 5px;
}

.analytics-btn {
  color: #7e8596;
  font-size: 12px;
  font-weight: 600;
}

.validation-badge {
  position: absolute;
  top: -5px;
  right: -5px;
  border-radius: 50%;
  font-size: 16px;
  background-color: #ffffff;
  color: #ff5757;
}
</style>
