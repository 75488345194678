<template>
  <transition-group
    v-if="!members.loading"
    tag="div"
    class="online-users"
    enter-active-class="animated fadeInUp"
    leave-active-class="animated fadeOutDown"
  >
    <div
      v-for="member in members.members"
      :class="['online-user', getUserClass(member)]"
      v-tooltip:bottom="member.email"
      :key="member.email"
    >
      <gravatar :email="member.email" :size="39" />
    </div>
  </transition-group>
</template>
<script>
export default {
  props: ['members'],

  data() {
    return {
      userClass: {
        count: 0,
        members: {}
      }
    }
  },

  methods: {
    getUserClass(user) {
      if (!this.userClass.members.hasOwnProperty(user.email)) {
        this.userClass.members[user.email] = `online-user-u${++this.userClass
          .count}`
      }

      return this.userClass.members[user.email]
    }
  }
}
</script>
<style lang="sass" scoped>
.online-user
    transition: all 1s
    float: right
    border: 2px solid #C4C4C4
    border-radius: 50%
    margin-right: -6px

    &.fadeOutDown
        position: absolute

    img
      border-radius: 50%

=user-color($name, $color)
    .online-user-#{$name}
        border-color: $color

+user-color(u1, #FF0000) // Red
+user-color(u2, #FF9900) // Safety Orange
+user-color(u3, #FFFF00) // Yellow
+user-color(u4, #00FF00) // Lime
+user-color(u5, #00FFFF) // Aqua
+user-color(u6, #4A86E8) // Havelock Blue
+user-color(u7, #0000FF) // Blue
+user-color(u8, #9900FF) // Vivid Violet
+user-color(u9, #FF00FF) // Violet
</style>
