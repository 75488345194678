<template>
  <div class="mini-map">
    <div class="wrapper" ref="wrapper">
      <div
        class="preview"
        ref="preview"
        @click="focus"
        @mousedown="onMouseDown"
      />
      <div class="view-box" ref="viewBox"></div>
    </div>
  </div>
</template>
<script>
import { throttle } from 'lodash'

const scale = 0.03,
  transformRegex =
    /translate\((-?\d+\.?\d*)px, (-?\d+\.?\d*)px\) scale\((\d*\.?\d+)\)/

export default {
  mounted() {
    const canvas = this.getCanvas()

    this.observer = new MutationObserver((mutations) => {
      let refresh = false,
        refreshViewBox = false

      mutations.forEach((mutation) => {
        if (mutation.attributeName === 'aria-describedby') return
        if (mutation.target.classList.contains('jtk-draggable')) return

        if (mutation.target === canvas) {
          refreshViewBox = true
        } else {
          refresh = true
        }
      })

      if (refresh) this.refresh()
      if (refreshViewBox) this.refreshViewBox()
    })
    this.observer.observe(canvas, {
      subtree: true,
      childList: true,
      attributes: true
    })

    this.refresh()
    this.refreshViewBox()
  },

  beforeDestroy() {
    this.observer.disconnect()
  },

  methods: {
    getCanvas() {
      return this.$parent.$refs.canvas
    },

    getViewPort() {
      return this.$parent.$refs.viewport
    },

    refresh: throttle(function () {
      if (!this.$refs.preview) return

      const canvas = this.getCanvas(),
        preview = this.$refs.preview,
        wrapper = this.$refs.wrapper

      preview.innerHTML = ''
      preview.style.width = `${canvas.offsetWidth * scale}px`
      preview.style.height = `${canvas.offsetHeight * scale}px`

      wrapper.style.width = `${canvas.offsetWidth * scale}px`
      wrapper.style.height = `${canvas.offsetHeight * scale}px`

      const clone = canvas.cloneNode(true)
      clone.style.transform = `scale(${scale})`
      preview.appendChild(clone)
    }, 100),

    getCanvasPos() {
      const pos = {
        top: 0,
        left: 0,
        scale: 0
      }

      const canvas = this.getCanvas()
      if (canvas) {
        const transform = canvas.style.transform.match(transformRegex)
        if (transform) {
          pos.left = transform[1]
          pos.top = transform[2]
          pos.scale = transform[3]
        }
      }

      return pos
    },

    refreshViewBox() {
      const canvasPos = this.getCanvasPos(),
        viewport = this.getViewPort(),
        viewBox = this.$refs.viewBox
      viewBox.style.left = `${Math.ceil(
        (-canvasPos.left / canvasPos.scale) * scale
      )}px`
      viewBox.style.top = `${Math.ceil(
        (-canvasPos.top / canvasPos.scale) * scale
      )}px`
      viewBox.style.width = `${
        (viewport.offsetWidth / canvasPos.scale) * scale
      }px`
      viewBox.style.height = `${
        (viewport.offsetHeight / canvasPos.scale) * scale
      }px`
    },

    focus(e) {
      this.$emit('changeFocus', {
        x: e.offsetX / scale,
        y: e.offsetY / scale
      })
    },

    onMouseDown() {
      document.addEventListener('mousemove', this.focusMove)
      document.addEventListener('mouseup', this.onMouseUp)
    },

    onMouseUp() {
      document.removeEventListener('mousemove', this.focusMove)
      document.removeEventListener('mouseup', this.onMouseUp)
    },

    focusMove(e) {
      this.$emit('changeFocus', {
        x: e.offsetX / scale,
        y: e.offsetY / scale
      })
    }
  }
}
</script>
<style lang="sass" scoped>
.mini-map
    z-index: 2
    position: absolute
    top: 125px
    right: 5px
    background-color: #fff
    width: 250px
    border: 1px solid #fff
    border-radius: 6px

    .wrapper
        position: relative
        z-index: 2
        background-color: #fff
        border-radius: 0 8px 0 0
        cursor: pointer
        overflow: hidden

        .preview
            ::v-deep *
                pointer-events: none

            ::v-deep .workflow-grid
                display: none

        .view-box
            z-index: 1
            position: absolute
            outline: 1px solid #D4D4D4
            pointer-events: none
            width: 10px
            height: 10px
            top: 0
            left: 0
</style>
