import EventEmitter from 'events'

class PanZoom extends EventEmitter {
  constructor(el, options = {}) {
    super()

    if (el.nodeType !== 1) {
      throw new Error('Invalid element passed to PanZoom')
    }

    this.options = Object.assign({}, PanZoom.defaults, options)

    this.el = el
    this.parent = el.parentNode

    this.transform = {
      scale: 0.8,
      left: 0,
      top: 0
    }

    this.panning = false

    this.onMouseDown = this.onMouseDown.bind(this)
    this.onMouseMove = this.onMouseMove.bind(this)
    this.onMouseUp = this.onMouseUp.bind(this)

    this.el.addEventListener('mousedown', this.onMouseDown)
  }

  destroy() {
    this.el.removeEventListener('mousedown', this.onMouseDown)
  }

  onMouseDown(e) {
    if (e.target !== this.el && !this.options.grabMode) {
      return
    }
    this.mouseDownPos = {
      left: this.transform.left,
      top: this.transform.top,
      x: e.clientX,
      y: e.clientY
    }

    document.body.classList.add(this.options.panningClass)
    document.addEventListener('mousemove', this.onMouseMove)
    document.addEventListener('mouseup', this.onMouseUp)
  }

  onMouseMove(e) {
    if (!this.panning) {
      const threshold = this.options.threshold
      if (
        Math.abs(this.mouseDownPos.x - e.clientX) > threshold ||
        Math.abs(this.mouseDownPos.y - e.clientY) > threshold
      ) {
        this.panning = true
        this.emit('panning')
      }

      return
    }
    this.setTransform(
      {
        left: this.mouseDownPos.left + (e.clientX - this.mouseDownPos.x),
        top: this.mouseDownPos.top + (e.clientY - this.mouseDownPos.y)
      },
      { animate: false }
    )
  }

  onMouseUp() {
    this.panning = false
    document.body.classList.remove(this.options.panningClass)

    delete this.mouseDownPos
    document.removeEventListener('mousemove', this.onMouseMove)
    document.removeEventListener('mouseup', this.onMouseUp)
  }

  zoomIn() {
    this.transform.scale === 0.3
      ? this.setScale(this.transform.scale + 0.1)
      : this.setScale(this.transform.scale + 0.2)
  }

  zoomOut() {
    this.setScale(this.transform.scale - 0.2)
  }

  setScale(scale) {
    this.emit('zoom', scale)
    scale = this.constrainScale(scale)

    const focus = this.getFocusPos()

    this.setTransform({
      scale: scale,
      left: -(focus.left * scale - this.parent.offsetWidth / 2),
      top: -(focus.top * scale - this.parent.offsetHeight / 2)
    })
  }

  getFocusPos() {
    const scale = this.transform.scale

    return {
      left: -this.transform.left / scale + this.parent.offsetWidth / scale / 2,
      top: -this.transform.top / scale + this.parent.offsetHeight / scale / 2
    }
  }

  /**
   * @param {HTMLElement} el
   * @param {object} [options]
   * @param {boolean} [options.animate]
   * @param {string} [options.constrain]
   * @param {number} [options.scale]
   * @param {number} [options.offsetLeft]
   * @param {number} [options.offsetTop]
   */
  focusElement(el, options = {}) {
    if (!el) return

    let scale = options.scale || this.transform.scale,
      left =
        -(el.offsetLeft * scale + (el.offsetWidth * scale) / 2) +
        this.parent.offsetWidth / 2,
      top =
        -(el.offsetTop * scale + (el.offsetHeight * scale) / 2) +
        this.parent.offsetHeight / 2

    left += options.offsetLeft || 0
    top += options.offsetTop || 0

    this.setTransform(
      {
        scale: scale,
        left: left,
        top: top
      },
      options
    )
  }

  focusPosition(left, top) {
    const scale = this.transform.scale

    this.setTransform({
      left: -(left * scale - this.parent.offsetWidth / 2),
      top: -(top * scale - this.parent.offsetHeight / 2)
    })
  }

  constrainScale(scale) {
    return Math.min(2, Math.max(0.3, Math.abs(parseFloat(scale))))
  }

  constrain(transform, constrain) {
    transform.scale = this.constrainScale(transform.scale)

    if (constrain === 'both' || constrain === 'left') {
      transform.left = Math.min(
        0,
        Math.max(
          transform.left,
          -(this.el.offsetWidth * transform.scale - this.parent.offsetWidth)
        )
      )
    }

    if (constrain === 'both' || constrain === 'top') {
      transform.top = Math.min(
        0,
        Math.max(
          transform.top,
          -(this.el.offsetHeight * transform.scale - this.parent.offsetHeight)
        )
      )
    }
  }

  /**
   *
   * @param {object} transform
   * @param {number} [transform.left]
   * @param {number} [transform.top]
   * @param {number} [transform.scale]
   * @param {object} [options]
   * @param {string} [options.constrain] both,none,left,top
   * @param {boolean} [options.animate]
   * @param {number} [options.duration]
   * @param {string} [options.easing]
   */
  setTransform(transform, options = {}) {
    options = Object.assign({}, this.options, options)

    Object.assign(this.transform, transform)
    this.constrain(this.transform, options.constrain)

    this.el.style.transformOrigin = '0 0'
    if (options.animate) {
      this.el.style.transition = `transform ${options.duration}ms ${options.easing}`
    } else {
      this.el.style.transition = 'none'
    }

    this.el.style.transform = `translate(${this.transform.left}px, ${this.transform.top}px) scale(${this.transform.scale})`
  }
}

PanZoom.defaults = {
  animate: true,
  duration: 300,
  easing: 'ease-in-out',
  panningClass: 'panning',
  threshold: 10,
  constrain: 'both'
}

export default PanZoom
