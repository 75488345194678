import { render, staticRenderFns } from "./WorkflowSideNav.vue?vue&type=template&id=b483989e&scoped=true"
import script from "./WorkflowSideNav.vue?vue&type=script&lang=js"
export * from "./WorkflowSideNav.vue?vue&type=script&lang=js"
import style0 from "./WorkflowSideNav.vue?vue&type=style&index=0&id=b483989e&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b483989e",
  null
  
)

export default component.exports