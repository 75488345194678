<template>
  <builder-toolbar
    :builder="beePlugin"
    :save-visible="false"
    @discard="onDiscard"
  ></builder-toolbar>
</template>
<script>
import BeePlugin from '@/libs/BeePlugin'
import Counter from '@/libs/Counter'
import appConfig from '@/config/config'
import Deferred from '@/libs/Deferred'
import VueDialog from '@/libs/VueDialog'
import BuilderToolbar from './Builder/Toolbar'
import SaveTemplateDialog from './Builder/SaveTemplateDialog'
import XpApi from '@/libs/XpApi'
import { isEqual } from 'lodash'
import { MessageBox } from 'element-ui'
import { BeeLanguageEnum } from './BeeLanguageEnum'

const builderCounter = new Counter()

export default {
  props: ['action', 'workflow'],
  components: {
    BuilderToolbar
  },

  inject: ['workflowApi', 'contentApi'],

  data: function () {
    return {
      isDeactivated: false,
      beePlugin: null,
      doSave: true,
      isDirty: false,
      isLoaded: false
    }
  },

  watch: {
    'action.contentId': {
      immediate: true,
      handler(contentId) {
        if (contentId) {
          this.contentApi.load(contentId).then((data) => {
            if (data.meta.editor === 'builder') {
              this.setContent(data)
            }
          })
        }
      }
    }
  },

  created() {
    this.loadBuilder()
  },

  beforeDestroy() {
    if (this.beePlugin && this.isLoaded) {
      this.save().finally(() =>
        this.builderCt.parentElement.removeChild(this.builderCt)
      )
    } else if (this.builderCt) {
      this.builderCt.parentElement.removeChild(this.builderCt)
    }
  },

  activated() {
    this.activateBuilder()
    if (this.beePlugin && this.isLoaded) {
      document.body.style.pointerEvents = 'auto'
    }
  },

  deactivated() {
    if (this.beePlugin && this.isLoaded) {
      this.save()
    }

    this.deactivateBuilder()
  },

  methods: {
    deactivateBuilder() {
      this.isDeactivated = true
      this.builderCt && this.builderCt.classList.add('deactivated')
    },
    activateBuilder() {
      this.isDeactivated = false
      this.builderCt && this.builderCt.classList.remove('deactivated')
    },
    async loadBuilder() {
      const [token, fields] = await Promise.all([
        this.contentApi.builderAuth(),
        this.$store.dispatch('fetchAttributes')
      ])

      const div = document.createElement('div')
      div.id = `builder-${builderCounter.increment()}`
      div.classList.add('builder-container')

      document.body.appendChild(div)
      this.builderCt = div
      if (this.isDeactivated) {
        // if component has been deactivated before builder was created
        this.deactivateBuilder()
      }

      const specialLinks = [
        {
          type: 'links',
          label: 'Unsubscribe',
          link: '{{ system.url.unsubscribe }}'
        },
        {
          type: 'links',
          label: 'View Online',
          link: '{{ system.url.archive }}'
        }
      ]

      const mergeTags = []
      fields.forEach((fieldMeta) => {
        mergeTags.push({
          name: fieldMeta.name,
          value: `{{ ${fieldMeta.name} }}`
        })
      })

      let projectId = this.$route.params.projectId

      const XpConfig = await XpApi.get(`/projects/${projectId}`)
      const shopboxEnabled = !!(XpConfig.data.shopbox_enabled || false)
      const vouchersEnabled =
        (this.workflow.features.voucher_codes || false) &&
        !!(XpConfig.data.allow_voucher_codes || false)
      const that = this

      const config = {
        uid: token.uid,
        container: div.id,
        language: this.beeLanguage(XpConfig.data.provider.language),
        specialLinks,
        mergeTags,
        addOns: [
          {
            id: 'product-recommendations-dialog',
            enabled: shopboxEnabled
          },
          {
            id: 'ai-integration',
            enabled: !!this.$store.getters.isFeatureEnabled('aiTextGeneration')
          },
          {
            id: 'voucher-codes-addon',
            enabled: vouchersEnabled
          }
        ],
        trackChanges: true,
        rowsConfiguration: {
          emptyRows: true,
          defaultRows: false,
          externalContentURLs: [
            {
              name: 'Saved',
              value:
                this.$store.getters.getXpBaseUrl +
                '/builder/saved-rows?project_id=' +
                projectId
            }
          ]
        },
        onChange: () => (this.isDirty = true),
        onLoad: () => {
          document.body.style.pointerEvents = 'auto'
          this.isLoaded = true
        },
        onSave: (newTemplate, html) => {
          if (!this.isLoaded) {
            // There is a bug if .save() goes right after .start() - newTemplate is empty.
            // It still needs some time to get loaded even after onLoad fired.
            return
          }

          if (this.saveTemplate) {
            this.saveTemplate = false
            this.onSaveAsTemplate(newTemplate, html)
            delete this.saveDefered
            return
          }

          let parsedNewTemplate = JSON.parse(newTemplate)
          this.saveDefered.resolve(parsedNewTemplate, html)

          if (
            !this.doSave ||
            this.contentId !== this.action.contentId ||
            (isEqual(this.template, parsedNewTemplate) && this.content === html)
          ) {
            delete this.saveDefered
            return
          }

          this.contentApi
            .saveBuilder(html, JSON.parse(newTemplate))
            .then(({ contentId }) => {
              this.workflowApi.updateAction(this.action.id, { contentId })
              delete this.saveDefered
              this.isDirty = false
            })
        },
        onSaveAsTemplate: () => {
          this.saveTemplate = true
          this.save()
        },
        onSaveRow: function (rowJSON, rowHTML, pageJSON) {
          XpApi.post('projects/' + projectId + '/content', {
            type: 1,
            content: rowHTML,
            meta: JSON.stringify({ rowJSON: JSON.parse(rowJSON), pageJSON })
          })
        },
        contentDialog: {
          saveRow: {
            handler: async function (resolve, reject, args) {
              try {
                let response = await MessageBox.prompt(null, 'Save Row', {
                  confirmButtonText: 'Save',
                  cancelButtonText: 'Cancel',
                  inputPlaceholder: 'Enter name'
                })
                resolve({ name: response.value })
              } catch (error) {
                reject()
              }
            }
          },
          addOn: {
            handler: async function (resolve, reject, args) {
              const { contentDialogId, value } = args

              if (contentDialogId === 'product-recommendations-dialog') {
                that.createBeePluginWindow(
                  resolve,
                  reject,
                  value,
                  'bee-shopbox-plugin'
                )
              } else if (contentDialogId === 'voucher-codes-addon') {
                that.createBeePluginWindow(
                  resolve,
                  reject,
                  value,
                  'bee-voucher-plugin'
                )
              } else {
                reject('Unknown plugin')
              }
            }
          }
        }
      }

      this.beePlugin = new BeePlugin(token, config)
      if (this.template) {
        this.beePlugin.start(this.template)
      }
    },

    onDiscard: async function () {
      if (this.isDirty) {
        try {
          await MessageBox.confirm(
            this.$t('builderCloseConfirmationMessage'),
            this.$t('builderCloseConfirmationTitle'),
            {
              type: 'warning',
              confirmButtonText: this.$t('alertOkText'),
              dangerouslyUseHTMLString: true
            }
          )
        } catch (error) {
          return
        }
      }
      this.doSave = false
      this.$emit('close')
    },

    save() {
      if (!this.saveDefered) {
        this.saveDefered = new Deferred()
        this.beePlugin.save()
      }

      return this.saveDefered.promise
    },

    onSaveAsTemplate(template, html) {
      VueDialog.show(SaveTemplateDialog, {
        projectId: this.$route.params.projectId,
        template,
        html
      })
    },

    setContent(content) {
      this.contentId = content.contentId
      this.content = content.content
      this.template = content.meta.template

      if (this.beePlugin) {
        this.beePlugin.start(this.template)
      }

      if (
        Array.isArray(this.template.comments) &&
        !this.template.comments.length
      ) {
        // Backend incorrectly converts empty objects to array
        this.template.comments = {}
      }
    },

    createBeePluginWindow(resolve, reject, value, componentName) {
      this.$vuedals.open({
        name: componentName + '-modal',

        props: {
          resolve: resolve,
          reject: reject,
          'current-state': this.decodeValue(value)
        },

        component: componentName,

        dismissable: false,

        closeOnBackdrop: false
      })
    },

    decodeValue(value) {
      if (!value.hasOwnProperty('html')) {
        return {}
      }

      const data = value.html
      try {
        const parser = new DOMParser()
        this.parsedData = JSON.parse(
          decodeURIComponent(
            parser
              .parseFromString(data, 'text/html')
              .documentElement.querySelector('div').dataset.pluginState
          )
        )
        return this.parsedData
      } catch (e) {
        // parsing exception
        return {}
      }
    },

    beeLanguage(providerLanguage) {
      let language = BeeLanguageEnum[this.$store.state.app.user.language]
      if (!language && providerLanguage) {
        language = BeeLanguageEnum[providerLanguage]
      }
      return language || BeeLanguageEnum['en']
    }
  }
}
</script>
<style lang="sass">
.builder-container
    z-index: 4
    position: absolute
    top: 164px
    right: 0
    width: 1200px
    bottom: 0
    overflow: hidden

    &.deactivated
        display: none
</style>
<style>
.vuedals {
  z-index: 10000000;
}

.vuedal {
  max-height: 85vh;
  margin: 80px 0;
}
</style>
